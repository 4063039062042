import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect as reactiveSearchConnect } from '@appbaseio/reactivesearch/lib/utils';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import SelectedFiltersComponent, { sortSelectedFilters } from './SelectedFiltersComponent';
import ResultStatsComponent from './ResultStatsComponent';
import SearchInputComponent from './SearchInputComponent';
import SortComponent, { sortOptions } from './SortComponent';
import FiltersComponent, { MultiListComponent } from './FiltersComponent';
import { pushKnowledgeHubFiltersUpdated } from '../../../../utils/gtm';
import { getBackendUrl } from '../../../../utils/url';

import NextArrow from '../../../../public/static/icons/chevron-right.svg';
import PrevArrow from '../../../../public/static/icons/chevron-left.svg';

import { propTypes as linkPropTypes } from '../../../01_atoms/Link';

import {
  ReactiveBase,
  DataSearch,
  MultiList,
  SelectedFilters,
  ReactiveList,
  StateProvider,
  ReactiveComponent,
} from '@appbaseio/reactivesearch';
import initReactivesearch from '@appbaseio/reactivesearch/lib/server';

import HubSearchResult from '../../../02_molecules/HubSearch/HubSearchResult';
import HubSearchNoResults from './HubSearchNoResults';
import { KNOWLEDGE_HUB_URL } from '../../../../utils/constants';
import * as breakpoints from '../../../../utils/breakpoint';
import LoadMoreButton from '../../../01_atoms/SearchElements/LoadMoreButton';
import { searchKnowledgeHubTouched } from '../../../../store/Slices/cnet/searchSlice';

const debug = require('debug')('cw:knowledgeHub');

import styles from './index.module.scss';

// This component is built on ReactiveSearch library.
// For more info and documentation see
// https://docs.reactivesearch.io/docs/reactivesearch/v3/overview/reactivebase/
//
// Renders search result item.
const renderResults = ({ data, loadMore, resultStats, loading }) => {
  if (!data.length) {
    return '';
  }
  return (
    <div
      className={`${styles['results-list']} ${
        loading ? styles['loading'] : 'results-loaded-testsuite'
      }`}
    >
      {data.map((item) => (
        <HubSearchResult item={item} key={item._id} />
      ))}
      {resultStats.numberOfResults > resultStats.displayedResults && (
        <LoadMoreButton onClick={loadMore} className={styles['kh-load-more-button']} />
      )}
    </div>
  );
};

// Renders featured item.
const renderFeaturedItem = (item) => <HubSearchResult item={item} key={item._id} featured />;

// Renders pagination.
const renderPagination = ({ pages, totalPages, currentPage, setPage }) => {
  if (totalPages === 1) {
    return null;
  }

  const realPage = currentPage + 1;
  let minPage = Math.max(1, realPage - (pages - 1) / 2);
  let maxPage = Math.min(totalPages, minPage + pages - 1);
  if (maxPage - minPage + 1 < pages) {
    minPage = Math.max(1, maxPage - pages + 1);
  }
  const items = [];
  for (let i = minPage; i <= maxPage; i++) {
    items.push(i);
  }

  return (
    <div className="results-pagination-wrapper">
      <div className="results-pagination">
        {realPage > 1 && (
          <div className="pagination-nav">
            <a
              className="pagination-previous"
              tabIndex="0"
              href={`?result=${realPage - 1}`}
              alt={`Page ${realPage - 1}`}
              rel="prev"
              onClick={(e) => {
                e.preventDefault();
                setPage(currentPage - 1);
                window.scrollTo(0, 0);
              }}
            >
              <PrevArrow alt="" />
              Previous
            </a>
          </div>
        )}
        {minPage > 1 && <span className="pagination-ellipsis">....</span>}
        {items.map((thisPage) => (
          <a
            key={thisPage}
            className={`pagination-item${thisPage === realPage ? ' active-item' : ''}`}
            tabIndex="0"
            href={`?result=${thisPage}`}
            alt={`Page ${thisPage}`}
            onClick={(e) => {
              e.preventDefault();
              setPage(thisPage - 1);
              window.scrollTo(0, 0);
            }}
          >
            {thisPage}
          </a>
        ))}
        {maxPage < totalPages && <span className="pagination-ellipsis">....</span>}
        {realPage < totalPages && (
          <div className="pagination-nav">
            <a
              className="pagination-next"
              tabIndex="0"
              href={`?result=${realPage + 1}`}
              alt={`Page ${realPage + 1}`}
              rel="next"
              onClick={(e) => {
                e.preventDefault();
                setPage(currentPage + 1);
                window.scrollTo(0, 0);
              }}
            >
              Next
              <NextArrow alt="" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

// Renders no result screen.
const renderNoResults = (
  filteredSearchValue,
  selectedFiltersWithoutSearch,
  noResults,
  exploreLinks,
) => {
  return (
    <HubSearchNoResults
      searchValue={filteredSearchValue}
      countSelectedFiltersForMessage={selectedFiltersWithoutSearch.length}
      noResultsText={noResults}
      exploreLinks={exploreLinks}
    />
  );
};

const HubSearch = ({
  noResults = '',
  exploreLinks = [],
  selectedValues = {},
  emptyResults = false,
  isSearchTouched = false,
  featuredOnHome = '',
  featuredOnSearch = '',
}) => {
  const [isHubHomePage, setIsHubHomePage] = useState(!isSearchTouched);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedFiltersOrder, setSelectedFiltersOrder] = useState(
    sortSelectedFilters(selectedValues, []),
  );
  const [sort, setSort] = useState(sortOptions[0]);
  const [isMobileFiltersOpened, setMobileFiltersOpened] = useState(false);
  const dispatch = useDispatch();

  const toggleMobileFilterPopup = () => {
    setMobileFiltersOpened(!isMobileFiltersOpened);
  };

  // Inform global Redux store about activated search. It's used in
  // ReactiveSearchAware component to manipulate page layout.
  useEffect(() => {
    if (isSearchTouched) {
      setIsHubHomePage(false);
      dispatch(searchKnowledgeHubTouched());
    }
  }, [isSearchTouched, dispatch]);

  const filteredSearch = selectedFiltersOrder.filter((item) => item.component === 'search');
  const filteredSearchValue = filteredSearch.length ? filteredSearch[0].value : '';

  // Maintain the order of applied filters in component state.
  useEffect(() => {
    const sortedFilters = sortSelectedFilters(selectedValues, selectedFiltersOrder);
    const selectedSearchValue = selectedValues.search ? `'${selectedValues.search}'` : '';
    // Update selected filters order only when count of filters was changed or Search query changes.
    if (
      sortedFilters.length !== selectedFiltersOrder.length ||
      filteredSearchValue !== selectedSearchValue
    ) {
      setSelectedFiltersOrder(sortedFilters);
    }
  }, [selectedValues, selectedFiltersOrder, filteredSearchValue]);

  // TODO: try to use CSS only for mobile UIs.
  useEffect(() => {
    function handleResize() {
      if (breakpoints.isUp('xl') && isMobile) {
        setIsMobile(false);
      }
      if (breakpoints.isDown('xl') && !isMobile) {
        setIsMobile(true);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  // Define first filter label.
  let firstFilterLabel = selectedFiltersOrder.length ? selectedFiltersOrder[0].value : '';
  // Use search query as first filter label if search query exists.
  firstFilterLabel = filteredSearchValue ? filteredSearchValue : firstFilterLabel;

  const selectedFiltersWithoutSearch = selectedFiltersOrder.filter(
    (filter) => filter.component !== 'search',
  );

  let countSelectedFiltersForMessage = selectedFiltersWithoutSearch.length;
  // We need to exclude first filter from count of filters if the search
  // query doesn't exists. In this case first filter label will be displayed.
  if (selectedFiltersOrder.length && !filteredSearchValue) {
    countSelectedFiltersForMessage--;
  }

  // Current page index is undefined by default, the value will appear after
  // changing page.
  const isNotFirstPage = selectedValues.result > 1;
  BBHubSearch.components.result.pagination = !isMobile;

  return (
    <div className={`container ${emptyResults ? styles['no-results'] : ''}`}>
      <a
        className={`${styles['back-button']} hide-on-hub-homepage analytics-hub-home-button`}
        href={KNOWLEDGE_HUB_URL}
      >
        Knowledge Hub Home
      </a>

      <StateProvider
        includeKeys={['isLoading']}
        onChange={(prevState, nextState) => {
          // Send data to GTM about applied filters.
          if (prevState.result.isLoading && !nextState.result.isLoading) {
            // eslint-disable-next-line no-unused-vars
            const { search, result, result_featured, ...filters } = selectedValues;
            const data = {
              resultsFound: !emptyResults,
              sort: sort.label,
              searchQuery: search || '',
              selectedFilters: filters,
            };
            pushKnowledgeHubFiltersUpdated(data);
          }
        }}
      />

      {!isHubHomePage && !emptyResults && (
        <StateProvider
          includeKeys={['resultStats', 'value']}
          // We need to update Stats every time when firstFilterLabel changes.
          strict={false}
          render={ResultStatsComponent(firstFilterLabel, countSelectedFiltersForMessage, 'mobile')}
        />
      )}

      <div className={styles['title-and-search-container']} aria-haspopup="true">
        <div className={styles['search-title']}>
          {firstFilterLabel ? firstFilterLabel : isHubHomePage ? '' : 'All Resources'}
        </div>

        <ReactiveComponent
          componentId="search"
          URLParams={true}
          render={({ setQuery }) => (
            <SearchInputComponent
              filteredValue={filteredSearchValue}
              inputPlaceholder={BBHubSearch.components.search.placeholder}
              submitSearchValue={(text) => {
                setQuery({
                  value: text,
                  query: text
                    ? BBHubSearch.components.search.customQuery(text, BBHubSearch.components.search)
                    : null,
                });
                window.scrollTo(0, 0);
              }}
            />
          )}
        />
      </div>

      <SelectedFilters
        className="selected-filters"
        render={SelectedFiltersComponent(
          selectedFiltersWithoutSearch,
          toggleMobileFilterPopup,
          isHubHomePage,
        )}
      />

      {!isHubHomePage && (
        <div className={`${styles['info-and-sort-container']} hide-when-no-results`}>
          {!emptyResults && (
            <StateProvider
              includeKeys={['resultStats', 'value']}
              // We need to update Stats every time when firstFilterLabel changes.
              strict={false}
              render={ResultStatsComponent(
                firstFilterLabel,
                countSelectedFiltersForMessage,
                'desktop',
              )}
            />
          )}

          <SortComponent onSortChange={setSort} currentSort={sort} isHubHomePage={false} />
        </div>
      )}

      <div
        className={`hub-search-results ${styles['hub-search-results']}${
          isHubHomePage ? ` ${styles['hub-search-results-home']}` : ''
        }`}
      >
        <div className="hub-search-results-container container-md">
          <div className="d-flex">
            <FiltersComponent
              isMobileFiltersOpened={isMobileFiltersOpened}
              toggleMobileFilterPopup={toggleMobileFilterPopup}
              countSelectedFiltersWithoutSearch={selectedFiltersWithoutSearch.length}
            />

            <div className="hub-search-results-list">
              <div id="result">
                {!isNotFirstPage && (selectedFiltersOrder.length || featuredOnHome) && (
                  <div
                    className={
                      isHubHomePage && featuredOnSearch
                        ? styles['featured-home']
                        : styles['featured']
                    }
                  >
                    <h2 className={styles['h2']}>Featured</h2>
                    <ReactiveList
                      {...BBHubSearch.components.result_featured}
                      defaultQuery={BBHubSearch.components.result_featured.getDefaultQuery(
                        selectedFiltersOrder.length === 0 ? featuredOnHome : undefined,
                      )}
                    />
                  </div>
                )}
                {isHubHomePage && !emptyResults && (
                  <div className={styles['all-resources-heading']}>
                    <h2 className={styles['h2']}>All resources</h2>
                    <SortComponent
                      onSortChange={setSort}
                      currentSort={sort}
                      isHubHomePage={isHubHomePage}
                    />
                  </div>
                )}
                <ReactiveList
                  {...BBHubSearch.components.result}
                  defaultQuery={() => ({
                    sort: [{ [sort.dataField]: { order: sort.sortBy } }],
                    min_score: 0,
                  })}
                  render={({ data, ...props }) =>
                    renderResults({
                      ...props,
                      // In all cases when we have featured content,
                      // we need to exclude featured item from results.
                      // If it is KH home page and we don't have featured content
                      // for /node/1868/edit in 'Featured content on the hub home page'
                      // field, we have to provide all items in general list.
                      // We do it on render rather than on query time because
                      // all queries are being sent at the same time
                      // in one _msearch request.
                      data: data.filter((item) => {
                        if (isHubHomePage && !featuredOnHome) {
                          return data;
                        }
                        return item._id !== featuredOnSearch;
                      }),
                    })
                  }
                  renderNoResults={() => {
                    return renderNoResults(
                      filteredSearchValue,
                      selectedFiltersWithoutSearch,
                      noResults,
                      exploreLinks,
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapReactiveSearchStateToProps = (state) => {
  // TODO: to rewrite with reduxjs/reselect.
  let selectedValues = {};
  let isSearchTouched = false;
  Object.keys(state.selectedValues).forEach((key) => {
    if (state.selectedValues[key].value) {
      const value = state.selectedValues[key].value;
      if (!Array.isArray(value) || value.length) {
        selectedValues[key] = value;
        isSearchTouched = true;
      }
    }
  });
  return {
    selectedValues,
    emptyResults: state.hits.result.total === 0,
    isSearchTouched,
    featuredOnSearch:
      state.hits.result_featured?.total > 0 ? state.hits.result_featured.hits[0]._id : '',
  };
};

HubSearch.propTypes = {
  noResults: PropTypes.string,
  exploreLinks: PropTypes.arrayOf(
    PropTypes.shape({
      nextLink: PropTypes.shape(linkPropTypes),
      label: PropTypes.string,
    }),
  ),
  selectedValues: PropTypes.shape({
    search: PropTypes.string,
    result: PropTypes.number,
    result_featured: PropTypes.number,
  }),
  emptyResults: PropTypes.bool,
  isSearchTouched: PropTypes.bool,
  featuredOnHome: PropTypes.string,
  featuredOnSearch: PropTypes.string,
};

// Connect to ReactiveSearch redux for quick access to common search data.
const ConnectedHubSearch = reactiveSearchConnect(mapReactiveSearchStateToProps, null)(HubSearch);

const BBHubSearch = ({ initialProps = null, ...props }) => {
  const router = useRouter();

  props.noResults = props.noResults ?? '';
  props.exploreLinks = props.exploreLinks ?? [];

  return (
    <ReactiveBase
      {...BBHubSearch.settings}
      initialState={initialProps}
      className={`bb bb-hub-search ${styles['bb-hub-search']}`}
      setSearchParams={(newURL) => {
        // Immediately add the new URL to history for ReactiveSearch to "see" it.
        if (router.asPath.startsWith(`${KNOWLEDGE_HUB_URL}?`)) {
          window.history.replaceState({ path: newURL }, '', newURL);
        } else {
          window.history.pushState({ path: newURL }, '', newURL);
        }

        // Asynchronously replace previous value with Next.js friendly URL.
        const url = new URL(newURL);
        // We don't await the operation to complete, because setSearchParams
        // callback doesn't support it.
        url.pathname = router.pathname;
        router.replace(url, newURL, { shallow: true });
      }}
      transformRequest={(req) => {
        // It's an ugly workaround to fix duplicated requests to Elasticsearch
        // when root query options (such as "min_score") are dynamically changed
        // by React.
        // If we change min_score in customQuery, ReactiveSearch generates
        // extra request and shows "no results" screen for a moment.
        // Related: https://github.com/appbaseio/reactivesearch/pull/1616
        if (req.body.includes('multi_match')) {
          return {
            ...req,
            body: req.body.replaceAll('"min_score":0', '"min_score":5'),
          };
        } else {
          return {
            ...req,
            body: req.body.replaceAll('"min_score":5', '"min_score":0'),
          };
        }
      }}
    >
      <ConnectedHubSearch {...props} />
    </ReactiveBase>
  );
};

// Prepare Reactivesearch settings and components.
// They are extracted from JSX for SSR.
// See https://opensource.appbase.io/reactive-manual/advanced/ssr.html.
BBHubSearch.settings = {
  app: 'resources_hub',
  url: getBackendUrl('/elastic-search/query/forward'),
};

// All basic search config should be available here for SSR.
BBHubSearch.components = {
  regions: {
    componentId: 'filter_regions',
    filterLabel: 'Region',
    dataField: 'filter_regions.keyword',
    showSearch: false,
    size: 100,
    sortBy: 'asc',
    URLParams: true,
    react: {
      and: [
        'search',
        //'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Regions'),
    defaultValue: [],
  },
  countries: {
    componentId: 'filter_countries',
    filterLabel: 'Country',
    dataField: 'filter_countries.keyword',
    showSearch: false,
    size: 100,
    sortBy: 'asc',
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        //'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Countries'),
    defaultValue: [],
  },
  themes_sectors: {
    componentId: 'filter_themes_sectors',
    filterLabel: 'Theme/Sector',
    dataField: 'filter_themes_sectors.keyword',
    showSearch: false,
    size: 100,
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        //'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Themes / Sectors'),
    defaultValue: [],
  },
  approaches: {
    componentId: 'filter_approaches',
    filterLabel: 'Approach',
    dataField: 'filter_approaches.keyword',
    showSearch: false,
    size: 100,
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        // 'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Approaches'),
    defaultValue: [],
  },
  languages: {
    componentId: 'filter_languages',
    filterLabel: 'Language',
    // It's indexed as a string, so no nested .keyword field here.
    dataField: 'filter_languages',
    showSearch: false,
    size: 100,
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        //'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Languages'),
    defaultValue: [],
  },
  programmes: {
    componentId: 'filter_programmes',
    filterLabel: 'Flagship Programme',
    dataField: 'filter_programmes.keyword',
    showSearch: false,
    size: 100,
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        //'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Flagship Programmes'),
    defaultValue: [],
  },
  types: {
    componentId: 'filter_types',
    filterLabel: 'Type',
    dataField: 'filter_types.keyword',
    showSearch: false,
    size: 100,
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        //'filter_types',
        'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Types'),
    defaultValue: [],
  },
  partners_networks: {
    componentId: 'filter_partners_networks',
    filterLabel: 'Partner / network',
    dataField: 'filter_partners_networks.keyword',
    showSearch: false,
    size: 100,
    URLParams: true,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        //'filter_partners_networks',
      ],
    },
    render: MultiListComponent('Partners / networks'),
    defaultValue: [],
  },
  search: {
    componentId: 'search',
    fuzziness: 'AUTO',
    showIcon: false,
    showClear: true,
    autosuggest: false,
    URLParams: true,
    defaultValue: '',
    iconPosition: 'right',
    placeholder: 'Search in Knowledge Hub',
    dataField: 'title',
    customQuery: (value, innerProps) => {
      if (!value || value === '') {
        return null;
      }

      const dataField = [
        {
          field: 'title',
          weight: 2,
        },
        {
          field: 'description',
          weight: 1,
        },
        {
          field: 'authors',
          weight: 0.5,
        },
        {
          field: 'bb_field_subheading',
          weight: 0.5,
        },
        {
          field: 'bb_field_heading',
          weight: 1,
        },
        {
          field: 'bb_text_processed',
          weight: 0.5,
        },
        {
          field: 'filter_regions',
          weight: 10,
        },
        {
          field: 'filter_countries',
          weight: 10,
        },
        {
          field: 'filter_themes_sectors',
          weight: 10,
        },
        {
          field: 'filter_approaches',
          weight: 10,
        },
        {
          field: 'filter_programmes',
          weight: 10,
        },
        {
          field: 'filter_types',
          weight: 10,
        },
        {
          field: 'filter_partners_networks',
          weight: 10,
        },
        {
          field: 'filter_tags',
          weight: 1,
        },
      ];

      // Grab query from Reactivesearch for altering.
      const should = DataSearch.shouldQuery(value, dataField, innerProps);

      // Change "best_fields" to "most_fields" to sum up findings scores across all fields.
      // It increases score if the same term is found in different fields.
      should[0].multi_match.type = 'most_fields';
      // Don't change first 2 letters when applying fuzziness.
      should[0].multi_match.prefix_length = 2;

      // Note that we set AND here instead of component props because
      // Reactivesearch disables FUZZINESS with AND.
      should.forEach((item, i) => {
        should[i].multi_match.operator = 'and';
      });

      // Default search doesn't find results with query search spread
      // across different fields. For query "emergency response in afghanistan",
      // if "emergency" is in tags, "response" in body blocks text and "Afghanistan" is in the country field,
      // then nothing will be found.
      // We perform two more searches to achieve cross field search.
      // Search 1: search inside meta field "all_text" with fuzziness enabled.
      const searchAcrossAllText = {
        match: {
          all_text: {
            query: should[0].multi_match.query,
            // We can control fuzziness here.
            fuzziness: 'AUTO',
            prefix_length: 2,
            minimum_should_match: '100%',
            // We cannot control per-field boost because everything is in one field.
            boost: 0.7,
          },
        },
      };
      should.push(searchAcrossAllText);
      // Search 2: perform cross_field multi_match search with pef-field boosts.
      const crossFieldsQuery = {
        multi_match: {
          ...should[2].multi_match,
          type: 'cross_fields',
          operator: 'and',
          // We cannot control fuzziness here, so "emergency response in afganistan" won't work.
        },
      };
      should.push(crossFieldsQuery);
      // Build final Elasticsearch query.
      return {
        query: {
          bool: {
            should,
            minimum_should_match: '1',
          },
        },
      };
    },
  },
  result_featured: {
    componentId: 'result_featured',
    size: 1,
    excludeFields: ['bb_*'],
    dataField: 'created',
    pagination: false,
    sortBy: 'desc',
    URLParams: false,
    infiniteScroll: false,
    scrollOnChange: false,
    showResultStats: false,
    renderNoResults: () => null,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    renderItem: renderFeaturedItem,
    getDefaultQuery: (featuredOnHome) => {
      if (featuredOnHome) {
        return () => ({
          query: {
            ids: { values: [featuredOnHome] },
          },
        });
      }

      return () => ({
        query: {
          term: { type: 'publication_landing' },
        },
      });
    },
  },
  result: {
    componentId: 'result',
    size: 30,
    excludeFields: ['bb_*'],
    dataField: 'created',
    pagination: true,
    sortBy: 'desc',
    pages: 9,
    URLParams: true,
    infiniteScroll: false,
    scrollOnChange: false,
    showResultStats: false,
    react: {
      and: [
        'search',
        'filter_regions',
        'filter_countries',
        'filter_themes_sectors',
        'filter_approaches',
        'filter_languages',
        'filter_programmes',
        'filter_types',
        'filter_partners_networks',
      ],
    },
    renderPagination,
  },
};

BBHubSearch.getInitialProps = async ({ query }, block) => {
  const hasFiltersOnServer =
    -1 !==
    Object.keys(query).findIndex((param) => param === 'search' || param.startsWith('filter'));
  const hasSearchOnServer = -1 !== Object.keys(query).findIndex((param) => param === 'search');

  // Normalise query parameters for the KH project to avoid 500 Error in case of wrong query.
  const normalizedQuery = {};
  Object.keys(query).forEach((key) => {
    if (key === 'search' && typeof query[key] === 'string') {
      normalizedQuery[key] = query[key];
    } else {
      try {
        const array = JSON.parse(query[key]);
        if (Array.isArray(array)) {
          normalizedQuery[key] = query[key];
        }
      } catch (error) {
        debug('Invalid query for the Knowledge hub: %O', error);
      }
    }
  });

  return await initReactivesearch(
    [
      {
        ...BBHubSearch.components.regions,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.countries,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.themes_sectors,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.approaches,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.languages,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.programmes,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.types,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.partners_networks,
        source: MultiList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
      {
        ...BBHubSearch.components.search,
        source: ReactiveComponent,
      },
      {
        ...BBHubSearch.components.result_featured,
        source: ReactiveList,
        defaultQuery: BBHubSearch.components.result_featured.getDefaultQuery(
          !hasFiltersOnServer ? block.featuredOnHome : undefined,
        ),
      },
      {
        ...BBHubSearch.components.result,
        source: ReactiveList,
        defaultQuery: () => ({
          min_score: hasSearchOnServer ? 5 : 0,
        }),
      },
    ],
    normalizedQuery,
    BBHubSearch.settings,
  );
};

BBHubSearch.propTypes = {
  initialProps: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.string),
  }),
  noResults: PropTypes.string,
  exploreLinks: PropTypes.arrayOf(
    PropTypes.shape({
      nextLink: PropTypes.shape(linkPropTypes),
      label: PropTypes.string,
    }),
  ),
};

export default BBHubSearch;
